import FinancingCompanyPage from 'components/pages/financing-for-companies/FinancingCompanyPage';
import Seo from 'components/Seo';
import { graphql } from 'gatsby';
import * as React from 'react';

const Index = () => (
	<div>
		<Seo
			title='Secure financing | FINTEQ'
			keywords='compare financing methods, learn more, education, knowledge, what is finteq, financing instruments, financing for companies'
			description='Find out which way of financing for businesses is the safest. Learn about the most popular methods, compare and choose wisely.'
			lang='en-US'
			locale='en_US'
		/>

		<FinancingCompanyPage />
	</div>
);

export default Index;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: {language: {eq: $language}}) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
